import Highcharts from "highcharts";

export function   getChartOptions(t: any, height: any, width: any, months: any,
  targetValueSet: any, kpiValueSet: any, target: any, commentValueMap: any,
  componentADescription: any, componentBDescription: any,
  componentCDescription: any, componentDDescription: any,componentValue:any,commentHeading:any,valuesHeading:any, kpiheading: any,targetheading: any): any {
  var currentPeriod = '';
  var currentKpi = '';
  const options = {
    chart: {
      type: 'line',
      height: height,
      width: width,
      plotBorderWidth: 1
    },
    title: {
      text: null
    },
    xAxis: {
      categories: months,
      width: width      
    },
    yAxis: {
      title: {
        text: ''
      },
      legend: {
        enabled: false
    }

    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        point: {
          events: {
            mouseOver: function (e: any) {
              console.error(e);
              currentPeriod = e.target.name;
              currentKpi = e.target.y;

            }
          }
        }
      }
    },
    tooltip: {
      formatter: function () {

        let kPiColor = `${componentValue.get(currentPeriod)["KpiValueColor"]}`
        let targetValue = `${componentValue.get(currentPeriod)["target"]}`
        var tooltip = '<div style="color:grey; font-size:14px">' + currentPeriod + '</div><br>'
        tooltip += '<div  style="font-size:14px;color:'+ kPiColor +'" >' + kpiheading +' :' 
        if(componentValue.get(currentPeriod)["kpiValue"] == 'NA'){
          tooltip += '-'
        }else{
          tooltip += `${componentValue.get(currentPeriod)["kpiValue"]}` +'%'
        }


        tooltip += '</div><br><div style="font-size:14px;color:#2469ff">'+ targetheading + ' :' + targetValue +'</div><br><br>'
      
        tooltip += '<div style="color:grey">-----------</div><br>'

        tooltip += '<div style="color:grey;font-size:14px">'+ commentHeading +'</div><br>'


        tooltip += '<div style="font-size:14px">' + `${commentValueMap.get(currentPeriod) != '' ?
          commentValueMap.get(currentPeriod) : "-"}` + '</div><br><br>'
        tooltip += '<div style="font-size:14px;color:grey">-----------</div><br>'

        tooltip += '<div style="color:grey">'+ kpiheading +'</div><br>'
        if (componentADescription != '') {
          tooltip += '<div style="font-size:14px">A ' + `${componentADescription} : ${componentValue.get(currentPeriod)["aValue"]}` + ' </div><br>'
        }
        if (componentBDescription != '') {
          tooltip += '<div style="font-size:14px">B ' + `${componentBDescription} : ${componentValue.get(currentPeriod)["bValue"]}` + '</div><br>'
        }
        if (componentCDescription != '') {
          tooltip += '<div style="font-size:14px">C ' + `${componentCDescription} : ${componentValue.get(currentPeriod)["cValue"]}` + '</div><br>'
        }
        if (componentDDescription != '') {
          tooltip += '<div style="font-size:14px">D ' + `${componentDDescription} : ${componentValue.get(currentPeriod)["dValue"]}` + '</div><br>'
        }

        return tooltip;
      }
    },

    series: [
      {
        data: targetValueSet,
        color: '#2469ff',
        showInLegend: false            
      },
      {
        data: kpiValueSet,
        color: 'black',
        showInLegend: false
      },
    ],
    credits: {
      enabled: false
    }
  };


  return options;
}


