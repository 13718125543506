import { useWidgetBusData } from "@sg-widgets/react-core";
import { useTranslation } from "react-i18next";

export class LanguageListener {
  private static readonly LANGUAGE_BUS_TOPIC = "global.language";

  public static getTranslationHandle() {
    const currentLanguage = useWidgetBusData<string>(this.LANGUAGE_BUS_TOPIC);
    const { t, i18n } = useTranslation();
    i18n.changeLanguage(currentLanguage);
    return t;
  }
}
