export class KPIReportHistoricalStatus {
  height:number;
  width:number;
  pageHeading: string;
  periods:Array<string>;
  kpiValueSet:any;
  targetValueSet:any;
  target:any;
  commentValueMap:any;
  componentADescription:any;
  componentBDescription:any;
  componentCDescription:any;
  componentDDescription:any;
  componentValueMap:any;
  constructor() {
    this.height = 0;
    this.width = 0;
    this.pageHeading = '';
    this.periods = [];
    this.kpiValueSet = [];
    this.targetValueSet = [];
    this.target='';
    this.commentValueMap={};
    this.componentADescription='';
    this.componentBDescription='';
    this.componentCDescription='';
    this.componentDDescription='';
    this.commentValueMap={};

  }
}
