import React from "react";
import { LanguageListener } from "../../common/language-listener";
import { KPIReportHistoricalStyles } from "../kpi-report-historical-styles";
export const LineChartLegend: React.FC = (): JSX.Element => {
    const t = LanguageListener.getTranslationHandle();
    return (
      <div style={KPIReportHistoricalStyles.legendContainerStyle}>
        <ul style={KPIReportHistoricalStyles.legendStyle}>
          <li style={KPIReportHistoricalStyles.listStyle}><span style={{ ...KPIReportHistoricalStyles.spanStyle, ...KPIReportHistoricalStyles.fontStyle,...KPIReportHistoricalStyles.target }}></span>{t('targetHeading')}</li>
          <li style={KPIReportHistoricalStyles.listStyle}><span style={{ ...KPIReportHistoricalStyles.spanStyle,...KPIReportHistoricalStyles.fontStyle, ...KPIReportHistoricalStyles.kpiValue }}></span>{t('kpiHeading')}</li>
        </ul>
      </div>
    );
  };