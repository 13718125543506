export abstract class KPIReportHistoricalStyles {
  public static ContainerStyle = { display: 'flex' };
  public static CountContainerStyle = { marginTop: '135px', marginRight:'20px' };
  public static legendStyle: any = { listStyle: 'none' }
  public static spanStyle: any = { float: 'left', width: 12, height: 12, margin: 4 };
  public static listStyle: any = { float: "left", marginRight: 10,};
  public static legendContainerStyle: any = { marginBottom: 50, marginLeft: 20 };
  public static target: any = { backgroundColor: '#2469ff' };
  public static kpiValue: any = { backgroundColor: 'black' };
  public static headingStyle: any = { marginTop: '0px' }
  public static fontStyle: any = { font: '14px' }
}
