import React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import '../i18n';
import {  KPIReportHistoricalStatus } from "./model/kpi-report-historical-status.model";
import { LanguageListener } from "../common/language-listener";
import { getChartOptions } from "./services/options-provider";
import { KPIReportHistoricalStyles } from "./kpi-report-historical-styles";
import { LineChartLegend } from "./components/line-chart-legend";

interface Props {
  kpiReportHistoricalStatus: KPIReportHistoricalStatus
}

const KpiReportHistorical: React.FC<Props> = ({ kpiReportHistoricalStatus = new KPIReportHistoricalStatus()}) => {
  const t = LanguageListener.getTranslationHandle();
  let options:any;
  let pageHeadingMessage = isWidgetNameSpecified(kpiReportHistoricalStatus) ? t('HistoricalView') : kpiReportHistoricalStatus.pageHeading;
  let width = getWidth(kpiReportHistoricalStatus);
  let height = getHeight(kpiReportHistoricalStatus);
  let commentHeading = t('Comment');
  let valuesHeading  = t('Values');
  let kpiHeading = t('kpiHeading')
  let targetHeading = t('targetHeading')
  

  options = getChartOptions(t, height ,width,kpiReportHistoricalStatus.periods,
    kpiReportHistoricalStatus.targetValueSet,kpiReportHistoricalStatus.kpiValueSet,
    kpiReportHistoricalStatus.target,kpiReportHistoricalStatus.commentValueMap,
    kpiReportHistoricalStatus.componentADescription, kpiReportHistoricalStatus.componentBDescription,
    kpiReportHistoricalStatus.componentCDescription, kpiReportHistoricalStatus.componentDDescription,
    kpiReportHistoricalStatus.componentValueMap,commentHeading,valuesHeading, kpiHeading, targetHeading);
  return (
    <div className="container display-3 border p-3">
      <h2 style={KPIReportHistoricalStyles.headingStyle}> {pageHeadingMessage}</h2>
      <div style={KPIReportHistoricalStyles.ContainerStyle}>
         <div>
           <LineChartLegend/>
          {<HighchartsReact highcharts={Highcharts} options={options} />}
        </div>
      </div>
    </div>
  );
};

widgetize("kpi-report-historical", KpiReportHistorical, {
  kpiReportHistoricalStatus: WidgetPropsMapping.asObject({
    description:
      "KPI Report Historical",
  }),
});

function isWidgetNameSpecified(kpiReportHistoricalStatus: KPIReportHistoricalStatus) {
  return (kpiReportHistoricalStatus.pageHeading == '' || kpiReportHistoricalStatus.pageHeading == null || kpiReportHistoricalStatus.pageHeading == 'undefined');
}

function getWidth(kpiReportHistoricalStatus: KPIReportHistoricalStatus) {
  return (kpiReportHistoricalStatus.width == 0 || kpiReportHistoricalStatus.width == null )?window.innerWidth-window.innerWidth*0.3:kpiReportHistoricalStatus.width;
}

function getHeight(kpiReportHistoricalStatus: KPIReportHistoricalStatus) {
  return (kpiReportHistoricalStatus.height == 0 || kpiReportHistoricalStatus.height == null)?250:kpiReportHistoricalStatus.width;
}

